












































import Vue from "vue";
import { Event, Doctor, Appointment } from "@/types/Types";

export default Vue.extend({
  data: () => ({
    focus: "",
    intervalId: 0,
    doctors: [] as Doctor[],
    appointments: [] as Appointment[],
  }),
  mounted() {
    // @ts-expect-error temp calls vuetify interal
    this.$refs.calendar.checkChange();
    this.updateAppointments();
    this.updateDoctors();
    this.intervalId = setInterval(() => {
      this.updateAppointments();
    }, 10000);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    // @ts-expect-error temp calls vuetify interal
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      // @ts-expect-error temp calls vuetify interal
      this.$refs.calendar.prev();
    },
    next() {
      // @ts-expect-error temp calls vuetify interal
      this.$refs.calendar.next();
    },
    // @ts-expect-error temp calls vuetify interal
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    updateAppointments() {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$store.getters.getToken);
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
      };

      fetch(
        "https://mdic-backend.herokuapp.com/api/v1/clinic/appointment",
        requestOptions
      )
        .then((response) => response.json())
        .then((appointments) => (this.appointments = appointments))
        .catch((error) => console.log("error", error));
    },
    updateDoctors() {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$store.getters.getToken);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
      };

      fetch(
        "https://mdic-backend.herokuapp.com/api/v1/clinic/drs",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => (this.doctors = result))
        .catch((error) => console.log("error", error));
    },
    getDoctorNameById(id: number) {
      let doctor = this.doctors.find((d: Doctor) => d.drId === id);
      if (!doctor) {
        return "";
      }
      return `${doctor.firstName} ${doctor.lastName}`;
    },
  },
  computed: {
    categories() {
      return this.doctors.map((dr: Doctor) => dr.drId.toString());
    },
    events(): Event[] {
      const events = [];

      for (const appointment of this.filteredAppointments) {
        let myDate = new Date();
        myDate.setHours(myDate.getHours() + 1);
        events.push({
          name: `${appointment.patientModel.firstname} ${appointment.patientModel.lastname}`,
          start: appointment.convertedStartDate,
          end: new Date(
            appointment.convertedStartDate.setMinutes(
              appointment.convertedStartDate.getMinutes() + 30
            )
          ),
          title: appointment.doctorName,
          color: "#4444bb",
          timed: true,
          category: appointment.drId.toString(),
        });
      }
      return events;
    },
    filteredAppointments(): Appointment[] {
      return JSON.parse(JSON.stringify(this.appointments)).map(
        (appointment: Appointment) => {
          let temp = appointment.startDate.split("T");
          let dayArray = temp[0].split(".");
          appointment.convertedStartDate = new Date(
            parseInt(dayArray[2]),
            parseInt(dayArray[1]) - 1,
            parseInt(dayArray[0]),
            parseInt(temp[1].split(":")[0]),
            parseInt(temp[1].split(":")[1])
          );
          let doctor = this.doctors.find((d) => d.drId == appointment.drId);
          appointment.doctorName = `${doctor?.firstName} ${doctor?.lastName}`;
          return appointment;
        }
      );
    },
  },
});
